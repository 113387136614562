.modal-overlay{
  background-color: rgba(0,0,0,.6);
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}

.modal{
  background-color: white;
  display: none;
  position: fixed;
  min-width: 20rem;
  min-height: 20rem;
  max-width: 90%;
  padding: 2rem;
  margin: 0 auto;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 10px;
}
.show{
  display: block !important;
  transition: all ease-in-out 2s;
}
